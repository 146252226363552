import type React from "react";
import { useEffect } from "react";

export interface UseClickOutsideProps {
	ref:
		| React.RefObject<HTMLElement | null>
		| React.RefObject<HTMLElement | null>[];
	isActive: boolean;
	onClick?: (ev: MouseEvent | TouchEvent) => any;
	withinRef?: React.RefObject<HTMLElement | null>;
}

export const useClickOutside = ({
	ref,
	isActive,
	onClick,
	withinRef,
}: UseClickOutsideProps) => {
	const refs = Array.isArray(ref) ? ref : [ref];
	useEffect(() => {
		if (isActive) {
			const handleClick = (event: MouseEvent | TouchEvent): void => {
				const target = event.target as HTMLElement | null;

				if (!refs.every((ref) => ref.current)) {
					return;
				}

				if (!target) {
					return;
				}

				if (refs.some((ref) => ref.current?.contains(target))) {
					return;
				}

				if (!document.body.contains(target)) {
					return;
				}

				// Add the `data-disable-click-outside-container` attribute to a container element to prevent
				// any click on a child within the container from triggering the onClick handler
				if (target.closest("[data-disable-click-outside-container]")) {
					return;
				}

				// Add the `data-disable-click-outside` attribute to a specific element to prevent
				// a click on that element from triggering the onClick handler.
				if (
					target instanceof HTMLElement &&
					target.dataset.disableClickOutside
				) {
					return;
				}

				if (withinRef && !withinRef?.current?.contains(target)) {
					return;
				}

				if (onClick) {
					event.stopPropagation();
					onClick(event);
				}
			};

			window.addEventListener("touchstart", handleClick, { passive: false });
			window.addEventListener("click", handleClick);

			return () => {
				window.removeEventListener("touchstart", handleClick);
				window.removeEventListener("click", handleClick);
			};
		}

		return undefined;
	}, [isActive]);
};
