import type React from "react";
import {
	type BaseSyntheticEvent,
	type ButtonHTMLAttributes,
	type HTMLAttributes,
	forwardRef,
} from "react";

import type { ComponentProps } from "@Components";
import { Key } from "@Key";
import { DOMInteractionEvent, useTrackingContext } from "@Providers";
import { keyboardOutline } from "../utils/outline";

export type TriggerEvent =
	| React.MouseEvent
	| React.KeyboardEvent
	| MouseEvent
	| KeyboardEvent
	| BaseSyntheticEvent;

export interface TriggerButtonProps
	extends Omit<ComponentProps, "as">,
		HTMLAttributes<HTMLDivElement | HTMLButtonElement> {
	onTrigger: (e: TriggerEvent) => void;
	disabled?: boolean;
	role?: "button" | "checkbox" | "tab";
	as?: "button" | "div";
	/** The `type` field of the HTML `button` element. To be used in conjunction with `as="button"`. */
	buttonType?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
	name?: HTMLButtonElement["name"];
	value?: HTMLButtonElement["value"];
}

export const TriggerButton = forwardRef<any, TriggerButtonProps>(
	(
		{
			as = "div",
			trackingAction,
			eventLabel,
			onTrigger,
			disabled = false,
			children,
			className,
			role = "button",
			buttonType,
			name,
			value,
			...restProps
		},
		ref,
	) => {
		const { trackEvent } = useTrackingContext();

		const onClick = (
			e: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>,
		): void => {
			if (disabled) {
				return;
			}

			onTrigger(e);
			if (trackingAction) {
				trackEvent(DOMInteractionEvent.CLICK, trackingAction, eventLabel);
			}
		};

		if (as === "button") {
			return (
				<button
					ref={ref}
					className={className}
					sx={{
						cursor: disabled ? "not-allowed" : "pointer",
						...keyboardOutline,
					}}
					{...restProps}
					// eslint-disable-next-line react/button-has-type
					type={buttonType}
					onClick={onClick}
					disabled={disabled}
					name={name}
					value={value}
				>
					{children}
				</button>
			);
		}

		return (
			// This rule errors when role is a variable eg role={role}
			// eslint-disable-next-line jsx-a11y/no-static-element-interactions
			<div
				ref={ref}
				role={role}
				aria-disabled={disabled ? true : undefined}
				tabIndex={disabled ? -1 : 0}
				onClick={onClick}
				onKeyDown={(e) => {
					if (disabled) {
						return;
					}

					if (e.key === Key.Enter || e.key === Key.Space) {
						e.preventDefault();
						onTrigger(e);
						if (trackingAction) {
							trackEvent(DOMInteractionEvent.KEYUP, trackingAction, eventLabel);
						}
					}
				}}
				className={className}
				sx={{
					cursor: disabled ? "not-allowed" : "pointer",
					display: "inline-block",
					...(disabled && {
						"&:focus": {
							outline: "none",
						},
					}),
					...keyboardOutline,
				}}
				{...restProps}
			>
				{children}
			</div>
		);
	},
);
