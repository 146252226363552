import { useEffect } from "react";

import type { Key } from "@Key";

export const useOnKeyEvent = (
	key: Key,
	onEvent?: (ev: KeyboardEvent) => any,
	deps: Array<any> = [],
) => {
	useEffect(() => {
		const handleKeys = (event: KeyboardEvent): void => {
			if (event.key === key && onEvent) {
				onEvent(event);
			}
		};

		window.addEventListener("keyup", handleKeys);

		return () => window.removeEventListener("keyup", handleKeys);
	}, deps);
};
